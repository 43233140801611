import React from 'react'
import ContactFormV1 from '../../../../../gatsby-theme-minimal/src/componentsList/ContactFormV1'
import Form from './v2/Form'
export default function ContactFormsContainer(props) {
  const {
    pageObjectKey,
    businessData,
    configData,
    // poweredImages,
    pageTitle,
    isMultiLocationSite
  } = props

  return (
    <>
      <div style={{ backgroundColor: '#F0F0E6' }}>
        <h2 style={{ maxWidth: '500px', padding: '1rem 1.5rem', margin: '0 auto', textAlign: 'center' }}>
          If you need to get in contact please reach out to the restaurant directly (203) 639-4830
        </h2>
      </div>
      {/* {pageObjectKey === 'contact' && (
        <ContactFormV1
          {...props}
          businessData={businessData}
          configData={configData}
          pageTitle={pageTitle}
          isMultiLocationSite={isMultiLocationSite}
          variantName={configData.contactForm.variantName}
          options={configData.contactForm.options}
        />
        
      )} */}
      {/* //  ! =============================================================*/}
      {/* //  ! WHEN Shaodwing Add Additional Contact Forms Here By Page Key */}
      {/* //  ! =============================================================*/}

      {pageObjectKey === 'reservations' && (
        <Form
          // {...props}
          // businessData={businessData}
          // configData={configData}
          // pageTitle={pageTitle}
          // isMultiLocationSite={isMultiLocationSite}
          // variantName={configData.contactForm.variantName}
          // options={configData.contactForm.options}
          formName="reservations-form"
          variantName={configData.contactForm.variantName}
          city={'ny'}
          isMultiLocationSite={false}
        />
      )}
    </>
  )
}
